<template>
  <section class="applications">
    <ul v-for="application of applications" :key="application.title" class="application-list">
      <li class="application-list-item">
        <h3>
          <a :href="application.url" target="_blank">{{ application.title }}</a>
        </h3>
        <img :src="application.imageUrl" :alt="application.title" />
        <div>{{ application.description }}</div>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      applications: s => s.applications.collection
    })
  },
  created() {
    this.$store.dispatch('applications/getCollection');
  }
};
</script>

<style lang="scss">
.applications {
  background-color: var(--theme-background);
  max-width: 768px;
  .application-list {
    margin: 0;

    .application-list-item {
      margin-bottom: 1rem;
      padding: 1rem;
      background-color: var(--theme-surface);

      display: grid;
      grid-template-columns: 50px minmax(0, 1fr);
      grid-template-rows: minmax(0, max-content) minmax(0, 1fr);
      grid-gap: 0.25rem 1rem;
      h3 {
        margin: 0;
      }
      img {
        grid-row: 1/3;
        grid-column: 1/2;
        place-self: center;
      }
    }
  }
}
</style>
