<template>
  <section>
    <hub-applications />
  </section>
</template>

<script>
import Applications from './../components/Applications.vue';

export default {
  components: {
    'hub-applications': Applications
  },
  mounted() {}
};
</script>
